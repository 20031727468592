/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import {
    shape, object, bool, func, string, number,
} from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useUIDSeed } from 'react-uid';
import ReactMarkdown from 'react-markdown';
import Typography from '@material-ui/core/Typography';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import generateValidCss from '../../../../helpers/contentstack/generateValidCss';
import parseStyles from '../../../../helpers/contentstack/parseStyles';
import Copy from '../../Copy';
import { ifExternalUrl, elementWidth } from '../helper';
import { trackEvent as track } from '../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import ResponsiveImage from '../../../GraphqlComponents/GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';
import { getFeatureFlags, getFeatureFlag, getPresentationFamily } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getIsBot } from '../../../../../state/ducks/App/App-Selectors';
import { openPassportSideBar } from '../../../../../state/ducks/Passport/Passport-Action';
import { getActiveABTest } from '../../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';
import positionFlexBox from '../../../../helpers/contentstack/copyPositionFlex';

const useStyles = makeStyles((theme) => ({
    nUp: (data) => ({
        maxWidth: '1400px',
        backgroundColor: (data.reference?.[0]?.background?.add_background) ? data.reference[0]?.background?.solid_background_color : 'none',
        margin: '0 auto',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: (data?.reference[0]?.reference?.length < 6 && data.reference?.[0]?.presentation_style !== 'Heading With Logo Image') ? 'center' : 'space-between',
        padding: data.reference?.[0]?.presentation_style === 'Showcase Container' ? '50px 10px 315px' : '0px',
        '@media screen and (max-width: 1080px)': {
            gap: '5px',
        },
        '@media screen and (max-width: 1023px)': {
            fontSize: '0.8em !important',
        },
        '@media screen and (max-width: 1400px)': {
            paddingBottom: data.reference[0]?.presentation_style === 'Showcase Container' ? 'calc(225px + 5.8vw)' : '0',
        },
        '@media screen and (max-width: 1050px)': {
            paddingBottom: data.reference[0]?.presentation_style === 'Showcase Container' ? 'calc(192px + 4vw)' : '0',
        },
    }),
    nUpBgImage: (data) => ({
        backgroundImage: `url(${data.reference[0]?.background?.images?.url}?auto=webp)`,
        backgroundSize: 'cover',
        backgroundPositionY: 'bottom',
    }),
    fullWidth: {
        maxWidth: '100%',
        width: '100%',
        '& $background_stroke': {
            '&::after': {
                width: '75%',
                margin: '0 auto',
            },
        },
    },
    header: {
        textAlign: 'center',
        width: '100%',
        margin: '0 0 8px',
        lineHeight: '.7em',
        letterSpacing: '.4px',
        fontSize: '1.4em',
        fontWeight: '300',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& h1,h2,h3,h4,h5,h6,p': {
            margin: '.75em 0 .5em',
            fontSize: 'inherit',
            fontWeight: 400,
        },
        '& img': {
            height: '30px',
            width: '30px',
            margin: '.75em 10px .5em',
        },
    },
    heading_paragraph_wrapper: {
        width: '100%',
        marginTop: '15px',
        marginBottom: '20px',
    },
    heading_paragraph: {
        margin: '0 auto',
        textAlign: 'center',
        width: '80%',
        lineHeight: '1.5em',
        letterSpacing: '.4px',
        fontSize: '1.4em',
        '& h1,h2,h3,h4,h5,h6,p': {
            margin: '0px 0 15px 0',
            fontSize: 'inherit',
            fontWeight: 'inherit',
        },
    },
    panel: (data) => ({
        textDecoration: 'none',
        backgroundSize: 'cover',
        pointerEvents: (data?.reference?.[0].nup_disable_linking) ? 'none' : '',
        color: '#1f1f1b',
        display: 'block',
        margin: (data?.reference[0]?.reference?.length < 6) ? '0' : '0 auto',
        paddingBottom: data?.reference[0]?.padding?.bottom || '1rem',
        paddingTop: data?.reference[0]?.padding?.top,
        paddingRight: data?.reference[0]?.padding?.right,
        paddingLeft: data?.reference[0]?.padding?.left,
        flexDirection: 'column',
        justifyContent: 'center',
        '&:focus:not(:link:active)': {
            outline: '-webkit-focus-ring-color auto 5px',
        },
        '@media screen and (min-width: 768px)': {
            '& img': {
                maxWidth: '100%',
                padding: '0px 20px 10px 20px !important',
                margin: 'auto !important',
            },
        },
        '@media screen and (max-width: 1200px)': {
            '& > span > div': {
                width: '90%',
                margin: '0 auto',
                wordBreak: 'break-word',
                whiteSpace: 'break-spaces',
            },
        },
    }),
    panelWithGrid: (data) => ({
        paddingBottom: data?.reference[0]?.padding?.bottom || '0',
    }),
    panelThreeItems: {
        flexDirection: 'row',
        '& div': {
            height: 'auto',
            width: 'fit-content',
            margin: '0 auto',
        },
    },
    root: {
        boxSizing: 'border-box',
        padding: theme.spacing(3, 0, 0, 0),
    },
    containerLink: {
        textDecoration: 'none',
    },
    imageContainer: {
        height: '90%',
        transition: 'all 0.3s ease 0s',
    },
    foodImageContainer: {
        '& > picture': {
            display: 'flex',
        },
    },
    imageFixedWidth: {
        maxWidth: '240px',
    },
    image: {
        width: '100%',
        height: 'auto',
    },
    foodNupImage: {
        '@media not all and (min-resolution:.001dpcm)': { // safari only solution
            '@media': {
                height: 'intrinsic !important',
            },
        },
    },
    messageWrapperLarge: {
        width: '95%',
        margin: '0px auto 0',
        '& span, picture': {
            display: 'flex',
        },
    },
    messageWrapperThreeItems: {
        margin: '-24px auto',
        border: theme.palette.gfTabDesktopInputBorder,
        '& span': {
            display: 'flex',
        },
        '@media screen and (max-width: 1200px)': {
            '& div': {
                width: '90%',
            },
        },
    },
    cta: {
        boxSizing: 'border-box',
        display: 'block',
        margin: '0 auto',
        fontSize: '18px',
        fontWeight: '800',
        borderRadius: '2px',
    },
    circleMask: {
        borderRadius: '50%',
    },
    Standard: theme.palette.nup?.Standard,
    Medium: theme.palette.nup?.Medium,
    Small: theme.palette.nup?.Small,
    Large: theme.palette.nup?.Large,
    Auto: {
        maxWidth: '100%',
    },
    background_stroke: {
        display: 'block',
        width: '100%',
        textAlign: 'center',
        '& p': {
            color: theme.palette.cta1,
            margin: '0 auto',
            padding: '0 .5em',
            textTransform: 'uppercase',
            fontSize: '1em',
            fontWeight: 400,
            lineHeight: '2.5em',
            display: 'inline-block',
            background: '#fff',
        },
        '&::after': {
            content: '""',
            position: 'relative',
            borderBottom: 'solid 1px #2f2f2f',
            display: 'block',
            width: '100%',
            top: '-1.37em',
            zIndex: '-1',
        },
    },
    setBackgroundImage: {
        margin: '0 auto',
    },
    dividers: (data) => ({
        backgroundSize: '100% 100%',
        borderRightColor: data.reference[0]?.vertical_entry_dividers?.divider_color || '#ccc',
        borderRightWidth: data.reference[0]?.vertical_entry_dividers?.width || '1px',
        borderRightStyle: 'solid',
        marginTop: data?.panelStyle?.marginTop,
        marginBottom: data?.panelStyle?.marginBottom,
        '&:last-child': {
            borderRight: 'none',
        },
    }),
    footerContainer: {
        margin: '10px auto 20px',
    },
    footerLink: {
        fontSize: '20px',
        textDecoration: 'none',
        color: theme.palette.black,
        fontFamily: 'latobold',
    },
    showcaseHeadingFirstWord: {
        color: '#5c656b',
    },
    showcaseHeadingRemaining: {
        color: '#4793b1',
    },
    nupContentAlign: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    nupContentTransform: {
        transform: 'initial !important',
    },
    showcaseHeadingFirstColor: (data) => ({
        color: data.reference[0]?.showcase_header_options?.heading_color_1?.color || '#2f2f2f',
    }),
    showcaseHeadingSecondColor: (data) => ({
        color: data.reference[0]?.showcase_header_options?.heading_color_2?.color || '#2f2f2f',
    }),
    panelStyling: ({ panelStyle }) => (panelStyle || {}),
    nupContactUs: {
        gap: '10px',
        '@media screen and (max-width: 1200px) and (min-width: 700px)': {
            gap: '5px',
        },
        '& $panelThreeItems': {
            '& div': {
                width: '100%',
            },
            '& img': {
                display: 'block',
            },
        },
        '& $messageWrapperThreeItems': {
            '& >div': {
                minHeight: '165px',
            },
        },
        '& .messageCopy': {
            '&:nth-of-type(1)': {
                minHeight: '120px',
            },
        },
    },
}));

const NUpDesktop = ({
    data, fixedMessage, trackEvent, isBot, presentationFamily, featureFlags, passportOpenSideBar, abTestShopByCategory,
}) => {
    if (!data?.reference?.[0]) return <></>;
    const itemList = data?.reference?.[0] || {};
    const location = useLocation();
    const contactUs = location?.pathname?.includes('contact-us');
    const hasDividers = () => itemList?.vertical_entry_dividers?.add_vertical_dividers;
    const getPanelStyles = () => {
        const dividerWidth = hasDividers() ? itemList?.vertical_entry_dividers?.width : 0;
        const getPanelDividerStyles = () => {
            if (!itemList?.vertical_entry_dividers?.add_vertical_dividers) return {};
            const dividerStyles = {
                marginTop: '20px',
                marginBottom: '30px',
            };
            return dividerStyles;
        };
        const panelStyles = getPanelDividerStyles();
        panelStyles.width = elementWidth(itemList, dividerWidth).width;
        panelStyles.textAlign = presentationFamily === 'flower' ? 'center' : '';
        return panelStyles;
    };

    const classes = useStyles({ ...data, panelStyle: getPanelStyles() });

    const [scale, setScale] = useState(false);
    const [blockKey, setKey] = useState(0);
    const handleMouseHover = (key, isHover) => {
        setScale(isHover);
        setKey(key);
    };

    if (data.reference?.[0]) {
        const zoomOnHover = itemList?.zoom_on_hover;
        let itemsReferences = itemList?.reference;

        const renderHeading = (nupData) => {
            /*
             * @description handles conditional header rendering and avoids nested ternaries in markup.
             * @param {object} nup data
             * @return heading markup for eithe standard or t3 showcase heading
            */
            const showcaseHeader = (nup) => {
                /*
                 * @description handles params and markup for larger showcase nup header as designed by t3.
                 * @param {object} header data
                 * @return markup with 2 font colors as described in classes in this component.
                */
                // const firstSpace = nup.heading.search(' ');
                const headingWords = nup?.heading.split(' ');
                let headingWithColor1 = nup?.heading;
                let headingWithColor2 = '';
                if (nup?.showcase_header_options?.word_count_for_color_1) {
                    headingWithColor1 = headingWords.slice(0, nup.showcase_header_options?.word_count_for_color_1).join(' ');
                    headingWithColor2 = headingWords.slice(nup.showcase_header_options?.word_count_for_color_1).join(' ');
                }
                return (
                    <Typography
                        variant={`h${nup.heading_level || 2}`}
                        className={`${classes.header} ${itemList?.heading_styles?.background_stroke && classes.background_stroke}`}
                        style={parseStyles(itemList.heading_styles?.font_styles)}
                    >
                        <span className={classes.showcaseHeadingFirstColor}>{headingWithColor1}</span>&nbsp;
                        <span className={classes.showcaseHeadingSecondColor}>{headingWithColor2}</span>
                    </Typography>
                );
            };
            if (nupData?.heading && itemList?.presentation_style !== 'Showcase Container') {
                return (
                    <div
                        className={`${classes.header} ${nupData.heading_styles?.background_stroke ? classes.background_stroke : ''}`}
                        style={parseStyles(nupData.heading_styles?.font_styles)}
                        data-testid="heading-font-styles"
                    >
                        {nupData?.heading_logo_image?.url && itemList?.presentation_style === 'Heading With Logo Image' && (
                            <ResponsiveImage
                                path={nupData?.heading_logo_image.url}
                                alt={nupData?.heading_logo_image?.description || 'Background Image'}
                                role="presentation"
                                dimensions={nupData?.heading_logo_image?.dimension}
                            />
                        )}
                        <ReactMarkdown source={nupData.heading} />
                    </div>
                );
            }
            if (nupData.presentation_style === 'Showcase Container') {
                return showcaseHeader(nupData);
            }
            return null;
        };

        const seed = useUIDSeed();
        let messageWrapper = classes.messageWrapperLarge;
        let isThreeItems = false;
        if (itemsReferences?.length === 3) { messageWrapper = classes.messageWrapperThreeItems; isThreeItems = true; }
        const nupContentAlign = presentationFamily === 'flower' && isThreeItems ? classes.nupContentAlign : '';
        const nupContentTransform = presentationFamily === 'flower' && data?.reference?.[0]?.full_width;
        const copyMessageData = (dataMessageCollection) => {
            const getBackgroundImage = dataMessageCollection?.use_image_as_background;
            return (!getBackgroundImage ? <div className={messageWrapper}><Copy data={dataMessageCollection?.messageCollection} /></div> : null);
        };

        // set padding top and bottom for general message
        const paddingStyleForCopy = (paddingStyle) => {
            const generalMessagePadding = {};
            if (paddingStyle && Array.isArray(paddingStyle)) {
                paddingStyle.forEach((item) => {
                    const attr = Object.keys(item)[0];
                    switch (attr) {
                        case 'padding':
                            generalMessagePadding.paddingBottom = `${item?.padding?.bottom}px`;
                            generalMessagePadding.paddingTop = `${item?.padding?.top}px`;
                            break;
                        default:
                            return generalMessagePadding;
                    }
                    return generalMessagePadding;
                });
            }
            return generalMessagePadding;
        };

        const getPanelImageData = (imageData) => {
            const getBackgroundImage = imageData?.use_image_as_background;
            const video = imageData?.image?.content_type === 'video/mp4';
            if (video) {
                return (
                    <div>
                        <video autoPlay loop muted width={presentationFamily === 'food' ? '86.5%' : '100%'} height="100%">
                            <source src={imageData?.image?.url} type="video/mp4" preload="metadata" />
                            <track kind="captions" />
                        </video>
                    </div>
                );
            }
            return (
                getBackgroundImage
                    ? (
                        <div
                            style={{
                                ...(generateValidCss(imageData.container_attributes, true) || {}),
                                backgroundImage: `url(${imageData?.image?.url}?auto=webp)`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundPositionX: imageData.tablet_x_offset || null,
                            }}
                            className={classes.setBackgroundImage}
                            data-testid="nup-backgroundImage"
                        >
                            {!fixedMessage
                                ? (<div style={generateValidCss(imageData?.message_styling, true)}><Copy data={imageData.message_collection} /></div>)
                                : (<div className={messageWrapper}><Copy data={imageData?.message_collection} /></div>)}
                        </div>
                    )
                    : (
                        <ResponsiveImage
                            style={generateValidCss(imageData.container_attributes, true)}
                            rootContainerStyle={positionFlexBox(imageData.message_positioning)}
                            path={imageData?.image?.url}
                            alt={imageData?.image?.description || 'Background Image'}
                            className={`${classes.image} ${presentationFamily === 'food' ? classes.foodNupImage : ''} ${(imageData?.container_attributes?.[0]?.circle_mask?.mask && classes.imageFixedWidth)} ${(classes[data?.reference?.[0]?.presentation_style])}`}
                            role="presentation"
                            dimensions={imageData?.image?.dimension}
                        />
                    )
            );
        };

        const handleClick = (e, title) => {
            if (title === 'Celebrations Passport'
                // VARIANT A is "Passport Enrolment Page"
                // CONTROL is "Passport Side Bar"
                && featureFlags['which-passport-sidebar-enabled-variant']?.variant === 'CONTROL') {
                e.preventDefault();
                passportOpenSideBar();
            }
        };

        const footer = (fields) => {
            if (fields) {
                const trackable = fields?.link;
                if (trackable || fields?.footer_copy) {
                    return (
                        <div className={classes.footerContainer}>
                            {fields.copy && <ReactMarkdown source={fields.copy} />}
                            {trackable?.link?.href && (
                                <Link
                                    to={trackable?.link?.href}
                                    title={trackable?.link?.title}
                                    className={classes.footerLink}
                                    data-ga-category={trackable?.tracking_event_category || ''}
                                    data-ga-action={trackable?.tracking_event_action || ''}
                                    data-ga-label={trackable?.tracking_event_label || ''}
                                    onClick={(e) => {
                                        trackEvent({
                                            eventCategory: trackable.tracking_event_category || '',
                                            eventAction: trackable.tracking_event_action || '',
                                            eventLabel: trackable.tracking_event_label || '',
                                        });
                                        ifExternalUrl(e, trackable?.link.href);
                                    }}
                                >{trackable?.link?.title}
                                </Link>
                            )}
                        </div>
                    );
                }
            }
            return null;
        };

        /**
         * AB testing block
         */
        if (itemList?.a_b_testing?.unique_id === 'shopByCategory') {
            const shopByCategoryVariant = abTestShopByCategory?.type?.toLowerCase?.() || null;
            if (shopByCategoryVariant === 'variant 1') {
                // added default 5 to show as per ticket but it can be managed by optimize
                let showCount = abTestShopByCategory?.desktopItemCount || 5;
                if (itemList?.row_count > 1 && !abTestShopByCategory?.desktopItemCount) {
                    showCount = Math.floor(itemsReferences?.length / itemList?.row_count);
                }
                itemsReferences = itemsReferences?.slice?.(0, showCount);
            } else if (shopByCategoryVariant === 'variant 2') {
                return <></>;
            }
        }
        const ffHasHomepageGridStructure = useSelector(getFeatureFlag('has-homepage-grid-structure'));
        const panelCssStyle = `${classes.panel} ${ffHasHomepageGridStructure && classes.panelWithGrid}`;
        return (
            <div
                className={`
                    ${classes.nUp}
                    ${itemList.full_width ? classes.fullWidth : ''}
                    ${itemList.background?.images ? classes.nUpBgImage : ''}
                    ${nupContentTransform ? classes.nupContentTransform : ''}
                    ${contactUs ? classes.nupContactUs : ''}
                `}
                data-testid="bg-elem"
            >
                { renderHeading(itemList) }
                {
                    itemList.heading_paragraph ? (
                        <div className={classes.heading_paragraph_wrapper}>
                            <div className={classes.heading_paragraph}>
                                <ReactMarkdown source={itemList?.heading_paragraph} />
                            </div>
                        </div>
                    ) : null
                }
                {
                    itemsReferences?.map((i, key) => {
                        const unit = i?.container_attributes?.[0]?.dimensions?.unit === 'Pixels' ? 'px' : '%';
                        const containerStyle =   {
                            width: i?.container_attributes?.[0]?.dimensions?.width  ? `${i.container_attributes?.[0]?.dimensions?.width }${unit}`  : '',
                            transform: zoomOnHover && presentationFamily !== 'food' && scale && key === blockKey ? `scale(${1.06})` : '',
                        };
                        const imageDimension = i.image?.dimension;
                        return (
                            <>
                                <Link
                                    onMouseOver={() => handleMouseHover(key, true)}
                                    onMouseLeave={() => handleMouseHover(key, false)}
                                    key={seed(i)}
                                    tabIndex="0"
                                    to={i.linking?.link?.href}
                                    title={i.linking?.link?.title}
                                    className={`${panelCssStyle} ${classes.panelStyling} ${isThreeItems && classes.panelThreeItems}`}
                                    data-ga-category={i.linking?.tracking_event_category || ''}
                                    data-ga-action={i.linking?.tracking_event_action || ''}
                                    data-ga-label={i.linking?.tracking_event_label || ''}
                                    onClick={(e) => {
                                        handleClick(e, i.linking?.link?.title);
                                        trackEvent({
                                            eventCategory: i.linking?.tracking_event_category || '',
                                            eventAction: i.linking?.tracking_event_action || '',
                                            eventLabel: i.linking?.tracking_event_label || '',
                                        });
                                        ifExternalUrl(e, i.linking?.link?.href);
                                    }}
                                >
                                    {i?.image
                                    /* If a backgroud image is provided, it will render as default product tile
                                    else it will render a div of provided dimension / presentation style */
                                        ? (
                                            <div>
                                                <div
                                                    style={
                                                        containerStyle
                                                    }
                                                    className={`
                                                    ${presentationFamily === 'flower' ? classes.imageContainer : `${classes.imageContainer} ${classes.foodImageContainer}`}
                                                `}
                                                >
                                                    {isBot ? <img src={`${i.image?.url  }?width=${imageDimension?.width}&height=${imageDimension?.height}&auto=webp`} alt={i.linking?.link?.title} /> : getPanelImageData(i)}
                                                </div>
                                                {i.message_collection?.length > 0 && (
                                                    <div style={generateValidCss(i.message_styling, true)} data-testid="nup-panel-msg">
                                                        <div style={paddingStyleForCopy(i.message_styling)}>
                                                            {(!fixedMessage && !i?.use_image_as_background) ? (<Copy data={i.message_collection} />) : (copyMessageData(i))}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        /* Added Copy components instead of manual switch case
                                        to avoid code repetition and to add more functionality to n-up panel.
                                        We can use Panel component directly but background image logic has not been implemented in it yet */
                                        )
                                        : (
                                            <div className={`${classes[data?.reference[0]?.presentation_style]} ${messageWrapper} ${nupContentAlign}`} style={generateValidCss(i.container_attributes, true)}>
                                                <div style={generateValidCss(i.message_styling, true)} data-testid="nup-panel-msg">
                                                    <div style={paddingStyleForCopy(i.message_styling)}>
                                                        <Copy data={i.message_collection} />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                </Link>
                                {((key + 1) < itemsReferences?.length) && hasDividers() && <div className={classes.dividers} />}
                            </>
                        );
                    })
                }
                {itemList.footer && footer(itemList.footer)}
            </div>
        );
    }
    return <></>;
};

NUpDesktop.propTypes = {
    isBot: bool,
    data: shape({
        disable_view_more: bool,
        display_on: string,
        full_width: bool,
        heading: string,
        heading_styles: shape({
            background_stroke: bool,
            font_styles: shape({
                font_color: object,
                font_family: string,
                font_size: string,
                font_weight: number,
                letter_spacing: string,
                line_height: string,
                text_align: string,
                text_decoration: string,
            }),
        }),
        footer: shape({
            footer_copy: string,
            footer_link: shape({
                title: string,
                href: string,
            }),
        }),
        background: shape({
            add_background: bool,
            images: object,
            solid_background_color: object,
        }),
        vertical_entry_dividers: shape({
            add_vertical_dividers: bool,
            divider_color: object,
            width: string,
        }),
    }).isRequired,
    reference: object.isRequired,
    fixedMessage: bool.isRequired,
    trackEvent: func.isRequired,
    presentationFamily: string.isRequired,
    featureFlags: shape({
        'which-passport-sidebar-enabled-variant': shape({
            variant: string,
        }),
    }),
    passportOpenSideBar: func,
    abTestShopByCategory: object,
};

NUpDesktop.defaultProps = {
    isBot: false,
    featureFlags: {},
    passportOpenSideBar: () => {},
    abTestShopByCategory: {},
};

const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(track, dispatch),
    passportOpenSideBar: bindActionCreators(openPassportSideBar, dispatch),
});

const mapStateToProps = (state) => ({
    presentationFamily: getPresentationFamily(state),
    isBot: getIsBot(state),
    featureFlags: getFeatureFlags(state),
    abTestShopByCategory: getActiveABTest('shopByCategory')(state),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NUpDesktop);
