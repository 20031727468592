/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/**
 *
 * @param {*} message_location
 * @returns Style
 * These values are already defined in CS.
 */
const positionVideoBox = (message_location) => {
    if (message_location !== undefined && message_location !== null) {
        let alignItems = 'center'; // vertical
        let justifyContent = 'center'; // horizontal
        switch (message_location?.toLowerCase?.()) {
            case 'center_center':
                alignItems = 'center';
                justifyContent = 'center';
                break;
            case 'flex-start_center':
                alignItems = 'center';
                justifyContent = 'flex-start';
                break;
            case 'flex-end_center':
                alignItems = 'center';
                justifyContent = 'flex-end';
                break;
            case 'center_top':
                alignItems = 'flex-start';
                justifyContent = 'center';
                break;

            case 'flex-start_top':
                alignItems = 'flex-start';
                justifyContent = 'flex-start';
                break;

            case 'flex-end_top':
                alignItems = 'flex-start';
                justifyContent = 'flex-end';
                break;

            case 'center_bottom':
                alignItems = 'flex-end';
                justifyContent = 'center';
                break;

            case 'flex-start_bottom':
                alignItems = 'flex-end';
                justifyContent = 'flex-start';
                break;

            case 'flex-end_bottom':
                alignItems = 'flex-end';
                justifyContent = 'flex-end';
                break;
            default:
                alignItems = 'center';
                justifyContent = 'center';
                break;
        }
        return {
            alignItems,
            justifyContent,
        };
    }
    return {};
};

export default positionVideoBox;
