/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/**
 * JSDoc for this function
 * @param {Object} font_styles object of font styles
 */

const parseStyles = (obj) => {
    // TODO: fold this object shape into generateValidCss()
    //       - this shape comes from a Global field and is therefore different
    //       - NupDesktop is essentially the testing bed for this Global Field (Styles - Typography)
    const styleObj = {};
    if (!obj && typeof obj !== 'object') return styleObj;
    Object.entries(obj).forEach((e) => {
        const key = e[0];
        const value = e[1];
        const snakeToCamelCase = (str) => {
            /*
             * @description Converts underscore delimited keys to camelCase
             *               - e.g. text_align -> textAlign
             * @param {string} e.g. 'font_family'
             * @return {string} e.g. 'fontFamily'
             */
            let split = str.split('_');
            split = split.filter((s) => s.length > 0);
            const camelledArray = [split[0]];
            for (let i = 1; i < split.length; i += 1) {
                camelledArray.push(split[i][0].toUpperCase() + split[i].substring(1));
            }
            const camelledStr = camelledArray.join('');
            return camelledStr;
        };
        if (value) {
            if (typeof value !== 'object') {
                switch (key) {
                    case 'font_family':
                        styleObj[snakeToCamelCase(key)] = value.replace('font-family:', '').replace(';', '');
                        break;
                    case 'font_size':
                        styleObj[snakeToCamelCase(key)] = `${value}px`;
                        break;
                    default:
                        styleObj[snakeToCamelCase(key)] = value;
                }
            } else if (value?.color) {
                styleObj.color = value.color || '#121212';
            }
        }
    });
    return styleObj;
};

export default parseStyles;
